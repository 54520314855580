import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { styled } from "styled-components";

import { Button, Card, Col, Form, Input, Row } from "antd";
import axiosInstance from "../../../axiosInterceptor";

const AlterarSenhaScreen = ({ onTabChange }) => {
  const dispatch = useDispatch();
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");

  const onFinish = (values) => {
    if (values.senha.trim() === "" || values.repitaSenha.trim() === "") {
      window.alert("A senha não pode ser em branco.");
    } else if (values.senha === values.repitaSenha) {
      confirmarAlteracao(values.senha);
    } else {
      window.alert("As senhas não correspondem. Por favor, verifique.");
    }
  };

  const confirmarAlteracao = async (senha) => {
    const confirmed = window.confirm("Deseja realmente alterar a senha?");

    if (confirmed) {
      const confirmed2 = window.confirm(
        "Deseja efetivar a alteração da senha?"
      );
      if (confirmed2) {
        try {
          const requestBody1 = {
            senha: senha,
          };
          const response1 = await axiosInstance.put(
            `https://auth2-tech.herokuapp.com/v1/usuarios/altsenha/${idUser}/${senha}`,
            requestBody1
          );

          if (response1.status === 200) {
            window.alert("Senha alterada com sucesso!");
          } else {
            window.alert("Erro", "Ocorreu um erro ao alterada a senha.");
          }
        } catch (error) {
          window.alert("Erro", "Ocorreu um erro ao alterada a senha.");
        }
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const handleButtonVoltar = () => {
    onTabChange("subTab1");
  };

  return (
    <Container>
      <center>
        <Card style={{ width: "80%", marginTop: "150px" }}>
          <Row>
            <Col xs={24} lg={24}>
              <FormContainer>
                <Form
                  name="passwordForm"
                  onFinish={onFinish}
                  layout="vertical"
                  style={{ width: "100%" }}
                >
                  <Form.Item
                    label="Senha"
                    name="senha"
                    rules={[
                      { required: true, message: "Por favor, insira a senha!" },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label="Repita a Senha"
                    name="repitaSenha"
                    rules={[
                      { required: true, message: "Por favor, repita a senha!" },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      ALTERAR
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ width: "100%" }}
                      onClick={handleButtonVoltar}
                    >
                      Voltar
                    </Button>
                  </Form.Item>
                </Form>
              </FormContainer>
            </Col>
          </Row>
        </Card>
      </center>
    </Container>
  );
};
const Container = styled.div`
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default AlterarSenhaScreen;

import React, { useEffect, useState } from "react";
import { Card, Table, Button, message } from "antd";
import styled from "styled-components";
import botApiUrl from "../../../botApiUrl";
import axiosInstance from "../../../axiosInterceptor";

const ContatosGrupos = ({arrayContatos, selectedInstancia}) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const idUser = window.localStorage.getItem("idUser");
    const [idLoja, setIDLoja] = useState(null);
  // Detecção de tamanho da tela
  const isMobile = window.innerWidth <= 768;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });


  const handleAdd = async (record) => {
    console.log(record);
    console.log(arrayContatos);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };

   // const body = { comando: JSON.stringify({ comando_string: "REMOVER_DO_GRUPO", parametros_string: [record.id, arrayContatos] }),"data":`${utcDateTime}`, nodeServer:`${window.localStorage.getItem("server")}` };
    const body = { comando: JSON.stringify({ comando_string: "ADICIONAR_AO_GRUPO", parametros_string: [record.id, record.instancia, arrayContatos] }), nodeServer:`${window.localStorage.getItem("server")}` };

    const resp = await botApiUrl.post(
    `v1/pilha-comandos/pushComNodeServer`, body,
      options
    );
    console.log(resp.status);
    if (resp.status == 201) {
      message.success('Adicionando ao grupo!');
    } else {
      message.error('Houve uma falha ao tentar adicionar.');
    }
  };
  

  // Colunas da tabela
  const columns = [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "Origem",
      dataIndex: "telOrigem",
      key: "telOrigem",
      render: (telOrigem) => formatarTelefone(telOrigem), 
    },
    {
      title: "Qtd Contatos",
      dataIndex: "quantidade",
      key: "quantidade",
    },
    {
      title: "Ações",
      key: "acoes",
      render: (_, record) => (
        <StyledButton onClick={() => handleAdd(record)}>Adicionar</StyledButton>
      ),
    },
  ];




  useEffect(() => {
    if (!idUser) return;

    const fetchData = async (page, pageSize) => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      if (!idLoja) {
        const resp = await axiosInstance.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options);
        setIDLoja(resp.data.id);
      }
    };
    fetchData(0, 20);
  }, [idUser]);

  const buscarGrupos = async (filtro) => {
    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    if (filtro === 'TODOS') {
      const resp2 = await botApiUrl.get(`/v1/grupos/loja/${idLoja}`, options);
      const registrosAux = [];
       resp2.data.content.map((data) => {
        registrosAux.push({
          key: data.id,
          ...data
        });
      });
      console.log(resp2.data);
      setData({ content: registrosAux });
      setPagination({
        current: resp2.data.pageable.pageNumber + 1,
        pageSize: resp2.data.pageable.pageSize,
        total: resp2.data.totalElements,
      });
    } else {
      const resp2 = await botApiUrl.get(`/v1/contatos/todosOrigem/${filtro}/${idLoja}`, options);
      const registrosAux = [];
      resp2.data.content.map((data) => {
        registrosAux.push({
          key: data.id,
          ...data
        });
      });
      console.log(resp2.data);
      setData({ content: registrosAux });
      setPagination({
        current: resp2.data.pageable.pageNumber + 1,
        pageSize: resp2.data.pageable.pageSize,
        total: resp2.data.totalElements,
      });
    }
    setLoading(false);
    return;
  };

  // useEffect para buscar os contatos
  useEffect(() => {

    if (!idUser) return;
    if (!accessToken) return;
    if (!idLoja) return;


    const fetchGrupos = async () => {
      try {
        setLoading(true);
         await buscarGrupos("TODOS");
      } catch (error) {
        message.error("Erro ao buscar contatos!");
      } finally {
        setLoading(false);
      }
    };

    fetchGrupos();
  }, [idLoja, accessToken]); // Array vazio para rodar apenas na montagem


  const formatarTelefone = (phoneNumber) => {
    if(!phoneNumber){
        return phoneNumber;
    }
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.startsWith('55') && (cleaned.length === 12 || cleaned.length === 13)) {
      const numberWithoutCountryCode = cleaned.substring(2);
      if (numberWithoutCountryCode.length === 11) {
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{5})(\d{4})$/, '+55 ($1) $2-$3');
      } else if (numberWithoutCountryCode.length === 10) {
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{4})(\d{4})$/, '+55 ($1) $2-$3');
      } else {
        return phoneNumber;
      }
    } else {
      return phoneNumber;
    }
  };

  return (
    <Container>
      <StyledCard title="Grupos de Contatos" bordered={true}>
        {isMobile ? (
          // Renderização de cards para mobile
          <MobileView>
            {data.content.map((item) => (
              <MobileCard key={item.key}>
                <p>
                  <strong>Nome:</strong> {item.nome}
                </p>
                <p>
                  <strong>Origem:</strong> {formatarTelefone(item.telOrigem)}
                </p>
                <p>
                  <strong>Qtd Contatos:</strong> {item.quantidade}
                </p>
                <StyledButton onClick={() => handleAdd(item)}>Adicionar</StyledButton>
              </MobileCard>
            ))}
          </MobileView>
        ) : (
          // Tabela para desktop
          <StyledTable
            dataSource={data.content}
            columns={columns}
            pagination={false} // Sem paginação
          />
        )}
      </StyledCard>
    </Container>
  );
};

export default ContatosGrupos;

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 1200px;
  border-radius: 8px;
`;

const StyledTable = styled(Table)`
  .ant-table {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
  }
`;

const StyledButton = styled(Button)`
  background-color: black;
  border: none;
  color: white;

  &:hover {
    background-color: #333;
    color: white;
  }
`;

const MobileView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MobileCard = styled(Card)`
  border: 1px solid #f0f0f0;
  border-radius: 8px;

  p {
    margin: 0 0 8px;
  }
`;

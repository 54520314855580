import React from "react";
import "./PageHeader.css"; // Importe seu arquivo de estilos CSS

const PageHeader = ({ tabName, nomeLoja, nomeUsuarioLogado }) => {
  return (
    <div className="page-header">
      <div className="left">{tabName}</div>
      <div className="center">{nomeLoja}</div>
      <div className="right">{nomeUsuarioLogado}</div>
    </div>
  );
};

export default PageHeader;

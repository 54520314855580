import React, { useState, useEffect } from "react";
import { Card, Button, Input, message, Col ,Modal} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faSave } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import clientePadraoApi from "../../../clientePadraoApi";

const ScheduleEditor = ({selectedBotID,loading, setLoading}) => {
    const accessToken = window.localStorage.getItem("accessToken");
  const [schedule, setSchedule] = useState({
    segundaFeira: [],
    tercaFeira: [],
    quartaFeira: [],
    quintaFeira: [],
    sextaFeira: [],
    sabado: [],
    domingo: []
  });

  const buscarAgenda =  async () => {
 
    const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
    try {
         //const response = await axios.get(`http://localhost:3012/v1/bot/buscarAgendamentoPorBotId/${selectedBotID}`, options);
        const response = await axios.get(`https://metabase.techbot.app.br/v1/bot/buscarAgendamentoPorBotId/${selectedBotID}`, options);
        if(response.data){
            console.log(response.data);
            setSchedule(response.data);
        }
      }  
      catch (error) {
        if (error.response) {
            console.log(error.response);
          // O servidor respondeu com um status fora da faixa de 2xx
          console.log('Status de erro:', error.response.status);
          console.log('Dados de erro:', error.response.data);
          console.log('Cabeçalhos de erro:', error.response.headers);

        } else if (error.request) {
          // A requisição foi feita, mas nenhuma resposta foi recebida
          console.log('Nenhuma resposta recebida:', error.request);
        } else {
          // Algo aconteceu ao configurar a requisição que acionou um erro
          console.log('Erro ao configurar a requisição:', error.message);
        }
      }
      
  };

  // Função para carregar o schedule a partir de um GET request
  useEffect(() => {
    if(accessToken){
        buscarAgenda();
    }   
 
  }, [accessToken]);

  // Função para adicionar um novo horário
  const addTimeSlot = (day) => {
    const newSlot = { inicio: "", fim: "" };
    setSchedule({
      ...schedule,
      [day]: [...schedule[day], newSlot]
    });
  };

  // Função para atualizar um horário existente
  const updateTimeSlot = (day, index, field, value) => {
    const updatedDay = schedule[day].map((slot, i) => 
      i === index ? { ...slot, [field]: value } : slot
    );
    setSchedule({ ...schedule, [day]: updatedDay });
  };

  // Função para remover um horário
  const removeTimeSlot = (day, index) => {
    const updatedDay = schedule[day].filter((_, i) => i !== index);
    setSchedule({ ...schedule, [day]: updatedDay });
  };

  // Função para salvar o schedule
  const saveSchedule = async () => {
    try {
      setLoading(true);
      const scheduleString = JSON.stringify(schedule);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      
      const response = await clientePadraoApi.put(`/v1/bot/salvarAgendamento/${selectedBotID}`, scheduleString, options);
      
      if (response.status === 201) {
        message.success('Agendamento Salvo!');
      } else {
        message.error('Falha ao salvar agendamento.');
      }
      
      console.log(response);
    } catch (error) {
      console.error("Erro ao salvar agendamento:", error);
      message.error('Erro ao salvar agendamento. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };
  



  const limparAgendamento = async () => {
    Modal.confirm({
      title: "Tem certeza?",
      content: "Deseja realmente limpar o agendamento?",
      okText: "Sim",
      cancelText: "Cancelar",
      onOk: async () => {
        try {
          setLoading(true);
          const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };
          
          const response = await clientePadraoApi.get(`/v1/bot/limparAgendamento/${selectedBotID}`, options);
          
          if (response.status === 204) { // Alterado para 204 No Content conforme a sugestão anterior
            message.success('Agendamento Limpo!');
            setSchedule({
              segundaFeira: [],
              tercaFeira: [],
              quartaFeira: [],
              quintaFeira: [],
              sextaFeira: [],
              sabado: [],
              domingo: []
            });
          } else {
            message.error('Falha ao limpar o agendamento.');
          }
          
          console.log(response);
        } catch (error) {
          console.error("Erro ao salvar agendamento:", error);
          message.error('Erro ao salvar agendamento. Tente novamente.');
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        message.info('Ação de limpar agendamento cancelada.');
      }
    });
  };
  
  














  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: "10px", marginTop:"10px" }}>
    <Card
      title="Agendamento do Bot"
      style={{ width: "100%", margin: "0 auto", textAlign: "center" }}
    >
      {Object.keys(schedule).map((day) => (
        <div key={day} style={{ marginBottom: "20px" }}>
          <h3>{day.charAt(0).toUpperCase() + day.slice(1)}</h3>
          {schedule[day].map((slot, index) => (
            <div key={index} style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
              <Input
                type="time"
                value={slot.inicio}
                onChange={(e) => updateTimeSlot(day, index, "inicio", e.target.value)}
                style={{ width: "100px" }}
              />
              <span style={{ margin: "0 10px" }}>até</span>
              <Input
                type="time"
                value={slot.fim}
                onChange={(e) => updateTimeSlot(day, index, "fim", e.target.value)}
                style={{ width: "100px" }}
              />
              <Button
                onClick={() => removeTimeSlot(day, index)}
                style={{ marginLeft: "10px", backgroundColor: "red", color: "white", border: "none" }}
                icon={<FontAwesomeIcon icon={faTrash} />}
              >
                Remover
              </Button>
            </div>
          ))}
          <Button
            onClick={() => addTimeSlot(day)}
            style={{ backgroundColor: "black", color: "white", border: "none" }}
            icon={<FontAwesomeIcon icon={faPlus} />}
          >
            Adicionar Horário
          </Button>
        </div>
      ))}
      <Button
        onClick={saveSchedule}
        loading={loading}
        style={{ marginTop: "20px", backgroundColor: "black", color: "white", border: "none" }}
        icon={<FontAwesomeIcon icon={faSave} />}
      >
        Salvar
      </Button>

      <Button
        onClick={limparAgendamento}
        loading={loading}
        style={{ marginTop: "20px", backgroundColor: "black", color: "white", border: "none" }}
        icon={<FontAwesomeIcon icon={faSave} />}
      >
        Limpar Agendamento
      </Button>
    </Card>
    </Col>
  );
};

export default ScheduleEditor;

import { message } from "antd";
import axios from "axios";

const clientePadraoApi = axios.create();

clientePadraoApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("idUser");
        window.location.reload();
      }else if (error.response.status === 500) {
        message.error("Erro 500: Problema interno no servidor.");
      }
    }else {
      message.error("Erro sem resposta do servidor:", error.message);
    }
    return Promise.reject(error);
  }
);
clientePadraoApi.defaults.baseURL = process.env.REACT_APP_CLIENTE_PADRAO_API_URL;
export default clientePadraoApi;

import React, { useEffect, useState } from "react";
import { Card, Form, Input, Modal, Skeleton, Select, Col, Row, Button, Table, notification, InputNumber, Checkbox } from "antd";
import { format, parseISO } from "date-fns";
import axiosInstance from "../../../axiosInterceptor";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import botApiUrl from "../../../botApiUrl";
import ContatosGrupos from "../grupos/ContatosGrupos";

const CrudMensagem = ({  arrayContatos,setArrayContatos,pagination,setPagination}) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loading, setLoading] = useState(true);
  const [registros, setRegistros] = useState([]);
  const [idLoja, setIDLoja] = useState(null);
  const [selectedInstancia, setSelectedInstancia] = useState(null);
  const [instancias, setInstancias] = useState([]);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [selectedItemsAux, setSelectedItemsAux] = useState(new Set());
  const [textoSelecao, setTextoSelecao] = useState('');
  const [pagina, setPagina] = useState(1);
  const [atualizarArray, setAtualizarArray] = useState(true);

  const [visibleExcluir, setVisibleExcluir] = useState(false);
  const [visibleBloquear, setVisibleBloquear] = useState(false);

  const { Option } = Select;

  const history = useHistory();

  const [arrayFantasmaPagina, setArrayFantasmaPagina] = useState([]);
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [checkboxTodos, setCheckboxTodos] = useState(false);
  const [telefoneOuNomeTodos, setTelefoneOuNomeTodos]= useState('');
  const [buscaDias, setBuscaDias]= useState('');
  const [nomeOuTelefone, setNomeOuTelefone]= useState('');

  const [buscarTags, setBuscarTags]= useState('');



  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenGrupos, setIsModalOpenGrupos] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [checkedTags, setCheckedTags] = useState([]);
  const [isLoadingTags, setIsLoadingTags] = useState(true); // Estado de carregamento
  const [selectedTagNames, setSelectedTagNames] = useState([]);
  const [selectedTagsById, setSelectedTagsById] = useState({}); // Armazena as tags selecionadas por ID
  const [grupos, setGrupos] = useState([]);


  const handleTableChangeGrupos = async (pagination) => {
    const { current, pageSize } = pagination;
    console.log(pagination);
    console.log(pagina);
    console.log(current);
    if(pagina !== current){
      setLoading(true);
      const response = await fetchData(current - 1, pageSize);
      setRegistros(response);
      console.log(response);
      const selectedRowKeysAux = [];
      response.content.map(contatoDaLista =>{
        arrayContatos.map((contatoDoArray)=>{
            if(contatoDaLista.key == contatoDoArray){
              selectedRowKeysAux.push(contatoDaLista.key);
            }
        })
      })
      setSelectedRowKeys(selectedRowKeysAux);
      setPagination({
        ...pagination,
        total: response.totalElements,
      });
      setLoading(false);
      setPagina(current);
    }

  };

  
  const fetchDataGrupos = async (page, pageSize) => {
    console.log('dentro da fetchData')
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    let url = '';
if(selectedInstancia == 'TODOS'){



if(buscaDias){
  console.log(buscaDias);
  url = `/v1/contatos/inativos/${selectedInstancia.toLowerCase()}/${idLoja}/${buscaDias?buscaDias:0}?page=${page}&size=${pageSize}`;
}else if(nomeOuTelefone){
  console.log(nomeOuTelefone);
  url =    `/v1/contatos/pegarTelOuNome/${selectedInstancia.toLowerCase()}/${idLoja}/${nomeOuTelefone}?page=${page}&size=${pageSize}`;
}else{
    url = `/v1/contatos/todosLoja/${idLoja}?page=${page}&size=${pageSize}`;
}

  const response = await botApiUrl.get(
    url,
    options
  );
  const registrosAux = [];
  response.data.content.map((data) => {
    registrosAux.push({
      key: data.id,
      ...data
    });
  });
  return {
    content: registrosAux,
    totalElements: response.data.totalElements,
  };
}else{



  if(buscaDias){
    console.log(buscaDias);
    url = `/v1/contatos/inativos/${selectedInstancia.toLowerCase()}/${idLoja}/${buscaDias?buscaDias:0}?page=${page}&size=${pageSize}`;
  }else if(nomeOuTelefone){
    console.log(nomeOuTelefone);
    url =    `/v1/contatos/pegarTelOuNome/${selectedInstancia.toLowerCase()}/${idLoja}/${nomeOuTelefone}?page=${page}&size=${pageSize}`;
  }else{
      url = `/v1/contatos/todosOrigem/${selectedInstancia}/${idLoja}?page=${page}&size=${pageSize}`;
  }

  const response = await botApiUrl.get(
    url,
    options
  );
    console.log(response.data);
    const registrosAux = [];
    response.data.content.map((data) => {
      registrosAux.push({
        key: data.id,
        ...data
      });
    });
    return {
      content: registrosAux,
      totalElements: response.data.totalElements,
    };


}

  };


  const [allTags, setAllTags] = useState([]);







  const [isMobile, setIsMobile] = useState(false);

  // Função para detectar se a tela é móvel
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Verificação inicial
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
const onChangeTodos = async (e) => {
  console.log(`${e.target.checked}`);
  setCheckboxTodos(e.target.checked);
  if(e.target.checked){
    console.log(selectedInstancia);
    console.log(telefoneOuNomeTodos);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
        if(selectedInstancia == 'TODOS'){
          const response = await axiosInstance.get(
            `https://deliverypdv.com.br/v1/contatos/todosLojaIds/${idLoja}`,
            options
          );
          setArrayContatos(response.data);

          console.log(response.data);
        }
        else{
          const response = await axiosInstance.get(
            `https://deliverypdv.com.br/v1/contatos/todosOrigemIds/${selectedInstancia}/${idLoja}`,
            options
          );
          setArrayContatos(response.data);

          console.log(response.data);
        }

 
  

  }else{
    setArrayContatos([]);
  }
};
  
  useEffect(() => {
    const keys = Object.keys(selectedItemsAux);
    const arrayContatosAux = [];
    keys.map((pagina) => {
      console.log(selectedItemsAux[pagina]);
      selectedItemsAux[pagina].map((contato)=>{
        arrayContatosAux.push(contato);
      })
     })
     setArrayContatos(arrayContatosAux);
     console.log('Passei pela contagem');
  }, [atualizarArray]);

  

  // Seleção da tabela
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log(newSelectedRowKeys);
    console.log(selectedRowKeys);
    if(newSelectedRowKeys.length > selectedRowKeys.length){
      console.log('Adicionando novo elemento');
      setSelectedRowKeys(newSelectedRowKeys);
      const arrayContatosAux = arrayContatos;
      const adicionarContato = newSelectedRowKeys.filter(key => !arrayContatos.includes(key));
      if(adicionarContato.length > 0){
        adicionarContato.map((contato)=>{
          arrayContatosAux.push(contato);
        }) 
      
      }
      console.log(arrayContatosAux);
      setArrayContatos(arrayContatosAux);
    }else if(newSelectedRowKeys.length < selectedRowKeys.length){
      console.log('Removendo elemento');
      const removido = selectedRowKeys.filter(key => !newSelectedRowKeys.includes(key));
      setSelectedRowKeys(newSelectedRowKeys.filter(key => selectedRowKeys.includes(key)));
      
      if(removido.length> 0){
            console.log(arrayContatos);
            setArrayContatos(prevContatos => 
              prevContatos.filter(key => !removido.includes(key))
            ); 
      }

    }


 /*
  // arrayAux[pagination.current]=(newSelectedRowKeys);
    console.log('setSelectedItemsAux');
    console.log(arrayAux);
    setSelectedItemsAux(arrayAux);

    console.log('newSelectedRowKeys');
    console.log(newSelectedRowKeys);
    const newSelectedItems = new Set(selectedItems);
    newSelectedRowKeys.forEach(key => newSelectedItems.add(key));
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedItems(newSelectedItems);
    setAtualizarArray(!atualizarArray);*/
  };



  useEffect(() => {
    if (idLoja && accessToken) {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      botApiUrl.get(
        `/v1/sessao/buscarPorIdLojaLogado/${idLoja}`,
        options
      ).then((resp) => {
        setInstancias(resp.data);
        setLoading(false);
      }).catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }
  }, [accessToken, idLoja]);



  useEffect(() => {
    const loadTags = async () => {
      const tags = await fetchTags(idLoja);
      setAllTags(tags);
      console.log(allTags)
    };

    loadTags();
  }, [idLoja]);

 useEffect(() => {
    if (!idUser) return;

    const fetchData = async (page, pageSize) => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      if (!idLoja) {
        const resp = await axiosInstance.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options);
        setIDLoja(resp.data.id);
      }
    };
    fetchData(0, 20);
  }, [idUser]);



  const handleTableChange = async (pagination) => {
    const { current, pageSize } = pagination;
    console.log(pagination);
    console.log(pagina);
    console.log(current);
    if(pagina !== current){
      setLoading(true);
      const response = await fetchData(current - 1, pageSize);
      setRegistros(response);
      console.log(response);
      const selectedRowKeysAux = [];
      response.content.map(contatoDaLista =>{
        arrayContatos.map((contatoDoArray)=>{
            if(contatoDaLista.key == contatoDoArray){
              selectedRowKeysAux.push(contatoDaLista.key);
            }
        })
      })
      setSelectedRowKeys(selectedRowKeysAux);
      setPagination({
        ...pagination,
        total: response.totalElements,
      });
      setLoading(false);
      setPagina(current);
    }

  };

  const buscarContatos = async (filtro) => {
    setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    if (filtro === 'TODOS') {
      const resp2 = await axiosInstance.get(`https://deliverypdv.com.br/v1/contatos/todosLojaTags/${idLoja}`, options);
      const registrosAux = [];
      resp2.data.content.map((data) => {
        registrosAux.push({
          key: data.id,
          ...data
        });
      });
      console.log(resp2.data);
      setRegistros({ content: registrosAux });
      setPagination({
        current: resp2.data.pageable.pageNumber + 1,
        pageSize: resp2.data.pageable.pageSize,
        total: resp2.data.totalElements,
      });
    } else {
      const resp2 = await axiosInstance.get(`https://deliverypdv.com.br/v1/contatos/todosOrigemTags/${filtro}/${idLoja}`, options);
      const registrosAux = [];
      resp2.data.content.map((data) => {
        registrosAux.push({
          key: data.id,
          ...data
        });
      });
      console.log(resp2.data);
      setRegistros({ content: registrosAux });
      setPagination({
        current: resp2.data.pageable.pageNumber + 1,
        pageSize: resp2.data.pageable.pageSize,
        total: resp2.data.totalElements,
      });
    }
    setLoading(false);
  };

  const formatarTelefone = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.startsWith('55') && (cleaned.length === 12 || cleaned.length === 13)) {
      const numberWithoutCountryCode = cleaned.substring(2);
      if (numberWithoutCountryCode.length === 11) {
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{5})(\d{4})$/, '+55 ($1) $2-$3');
      } else if (numberWithoutCountryCode.length === 10) {
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{4})(\d{4})$/, '+55 ($1) $2-$3');
      } else {
        return phoneNumber;
      }
    } else {
      return phoneNumber;
    }
  };

  const safeLocaleCompare = (a, b) => {
    if (a == null) return -1;
    if (b == null) return 1;
    return a.localeCompare(b);
  };



  const fetchTags = async () => {
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`, // Substitua pelo seu método de autenticação
      },
    };
  
    try {
      // Faz a requisição para buscar todas as tags da loja
      const response = await botApiUrl.get(`/api/tags/loja/${idLoja}`, options);
      console.log(response.data);
      return response.data; // Retorna as tags
      
    } catch (error) {
      console.error("Erro ao buscar tags:", error);
      return []; // Retorna um array vazio em caso de erro
    }
  };



  const openTagModal = async (contactId) => {
    setSelectedId(contactId);
    setIsModalOpen(true);
    setIsLoadingTags(true);
  
    try {
      // Buscar todas as tags disponíveis
      const tags = await fetchTags(idLoja);
      setAllTags(tags); // Atualiza todas as tags disponíveis
      console.log(tags)
  
      // Buscar as tags selecionadas para o contato
      const selectedTags = await fetchContactTags(contactId); // Função que busca as tags do contato
      console.log(selectedTags);


      // Preencher os tags selecionados
      setCheckedTags(selectedTags.map(tag => tag.idTag)); // Atualiza os tags selecionadas
    } catch (error) {
      console.error("Erro ao abrir modal de tags:", error);
    } finally {
      setIsLoadingTags(false); // Certifique-se de definir o loading como false
    }
  };
  
    const handleTagCheck = (tagId) => {
      setCheckedTags((prevCheckedTags) =>
        prevCheckedTags.includes(tagId)
          ? prevCheckedTags.filter((id) => id !== tagId)
          : [...prevCheckedTags, tagId]
      );
    };




    const saveTags = async () => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Autenticação
        },
      };
    
      try {
        const payload = {
          idContato: selectedId,
          tags: checkedTags,
        };
    
        console.log(payload);
    
        const response = await botApiUrl.post(`/api/tags/update`, payload, options);
    
        if (response.status === 204) {
          notification.success({
            message: "Sucesso",
            description: "Tags atualizadas com sucesso para o contato!",
          });
    
          console.log("Tags atualizadas com sucesso para o contato!");
    
          // Obter os nomes das tags atualizadas
          let tagNames = [];
          try {
            if (checkedTags.length > 0) {
              const response2 = await botApiUrl.post(`/api/tags/nomes`, checkedTags, options);
              if (response2.status === 200 && response2.data) {
                tagNames = response2.data;
              }
            }
          } catch (error) {
            console.error("Erro ao trazer tags:", error);
          }
    
          // Atualizar a lista mantendo todos os registros e substituindo as tags do contato selecionado
          const arrayAux = registros.content.map(element => {
            if (element.id === selectedId) {
              // Substituir o array de tags ao invés de adicionar ao existente
              return {
                ...element,
                tagNames: tagNames,
              };
            }
            return element;
          });
    
          setRegistros({
            content: arrayAux,
            totalElements: registros.totalElements,
          });
    
          console.log(registros);
        } else {
          notification.error({
            message: "Erro",
            description: `Erro ao atualizar tags: ${response.status}`,
          });
          console.error("Erro ao atualizar tags:", response.status);
        }
      } catch (error) {
        console.error("Erro ao salvar tags:", error);
        notification.error({
          message: "Erro",
          description: "Erro ao salvar tags",
        });
      } finally {
        setIsModalOpen(false);
      }
    };
    


    const fetchContactTags = async (contactId) => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
    
      try {
        const response = await botApiUrl.get(`/api/tags/ligacao/contato/${contactId}`, options);
        console.log(response);
        return response.data; // Retorna as tags do contato
      } catch (error) {
        console.error("Erro ao buscar tags do contato:", error);
        return []; // Retorna um array vazio em caso de erro
      }
    };


  const columns = [
    {
      title: "Nome",
      dataIndex: "nome",
      sorter: (a, b) => safeLocaleCompare(a.nome, b.nome),
    },
    {
      title: "Tags",
      dataIndex: "tagNames",
      render: (tagNames) => (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', maxWidth: '300px' }}> {/* Define a largura máxima da coluna */}
          {tagNames && tagNames.length > 0 ? (
            tagNames.map((tag, index) => (
              <div key={index} style={{
                backgroundColor: 'white', // Fundo branco
                borderColor: 'green', // Cor da borda verde
                borderWidth: '1px', // Largura da borda
                borderStyle: 'solid', // Estilo da borda
                color: 'green', // Cor do texto verde
                padding: '2px 4px', // Padding para a tag
                borderRadius: '5px',
                fontSize: '12px', // Tamanho da fonte
                whiteSpace: 'nowrap', // Impede que o texto quebre em várias linhas
                overflow: 'hidden', // Oculta o texto que excede o maxWidth
                textOverflow: 'ellipsis', // Adiciona '...' se o texto for muito longo
              }}>
                {tag}
              </div>
            ))
          ) : (
            <span>Nenhuma tag</span>
          )}
        </div>
      )
    },
    
    {
      title: "Telefone",
      dataIndex: "telefone",
      sorter: (a, b) => safeLocaleCompare(a.telefone, b.telefone),
      render: (date) => formatarTelefone(date),
    },
    {
      title: "Origem",
      dataIndex: "telOrigem",
      sorter: (a, b) => safeLocaleCompare(a.telOrigem, b.telOrigem),
      render: (date) => formatarTelefone(date),
    },
    {
      title: "Última Interação",
      dataIndex: "ultimaInteracao",
      sorter: (a, b) => new Date(a.ultimaInteracao) - new Date(b.ultimaInteracao),
      render: (date) => format(new Date(date), "dd/MM/yyyy HH:mm"),
    },
    {
      title: "Ações",
      dataIndex: "",
      render: (record) => (
        <Button onClick={() => openTagModal(record.id)}>#</Button>
      ),
    },
  ];
  
  

  const handleInstanciaChange = (value) => {
    if(value != selectedInstancia){
      setArrayContatos([]);
      setSelectedRowKeys([]);
      setPagina(0);
      setBuscaDias('');
      setNomeOuTelefone('');
      setBuscarTags('');
      form2.resetFields(['buscar']); 
      form1.resetFields(['dias']); 
      form3.resetFields(['nomeTag']); 
    }
   
    if(value != null){
      setSelectedInstancia(value);
      buscarContatos(value);
      setTextoSelecao('');
    }

  };

  const formatDateString = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "dd/MM/yyyy HH:mm");
  };

  const fetchData = async (page, pageSize) => {
    console.log('dentro da fetchData')
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    let url = '';
if(selectedInstancia == 'TODOS'){



if(buscaDias){
  console.log(buscaDias);
  url = `https://deliverypdv.com.br/v1/contatos/inativosTags/${selectedInstancia.toLowerCase()}/${idLoja}/${buscaDias?buscaDias:0}?page=${page}&size=${pageSize}`;
}else if(nomeOuTelefone){
  console.log(nomeOuTelefone);
  url =    `https://deliverypdv.com.br/v1/contatos/pegarTelOuNomeTags/${selectedInstancia.toLowerCase()}/${idLoja}/${nomeOuTelefone}?page=${page}&size=${pageSize}`;
}else{
    url = `https://deliverypdv.com.br/v1/contatos/todosLojaTags/${idLoja}?page=${page}&size=${pageSize}`;
}

  const response = await axiosInstance.get(
    url,
    options
  );
  const registrosAux = [];
  response.data.content.map((data) => {
    registrosAux.push({
      key: data.id,
      ...data
    });
  });
  return {
    content: registrosAux,
    totalElements: response.data.totalElements,
  };
}else{



  if(buscaDias){
    console.log(buscaDias);
    url = `https://deliverypdv.com.br/v1/contatos/inativosTags/${selectedInstancia.toLowerCase()}/${idLoja}/${buscaDias?buscaDias:0}?page=${page}&size=${pageSize}`;
  }else if(nomeOuTelefone){
    console.log(nomeOuTelefone);
    url =    `https://deliverypdv.com.br/v1/contatos/pegarTelOuNomeTags/${selectedInstancia.toLowerCase()}/${idLoja}/${nomeOuTelefone}?page=${page}&size=${pageSize}`;
  }else{
      url = `https://deliverypdv.com.br/v1/contatos/todosOrigemTags/${selectedInstancia}/${idLoja}?page=${page}&size=${pageSize}`;
  }

  const response = await axiosInstance.get(
    url,
    options
  );
    console.log(response.data);
    const registrosAux = [];
    response.data.content.map((data) => {
      registrosAux.push({
        key: data.id,
        ...data
      });
    });
    return {
      content: registrosAux,
      totalElements: response.data.totalElements,
    };


}

  };



    // Renderização da visualização em Cards para dispositivos móveis
    const renderMobileCards = () => {
      if(registros.content){
        console.log(registros.content);
       return registros.content.map(record => (
          <Card key={record.id} style={{ marginBottom: '16px', backgroundColor: '#f5f5f5' }}>
            <p><strong>Nome:</strong> {record.nome}</p>
            <p><strong>Tags:</strong> {record.tagNames && record.tagNames.length > 0 ? record.tagNames.join(', ') : "Nenhuma tag"}</p>
            <p><strong>Telefone:</strong> {formatarTelefone(record.telefone)}</p>
            <p><strong>Origem:</strong> {formatarTelefone(record.telOrigem)}</p>
            <p><strong>Última Interação:</strong> {format(new Date(record.ultimaInteracao), "dd/MM/yyyy HH:mm")}</p>
            <Button onClick={() => openTagModal(record.id)}>Ações</Button>
          </Card>
        )) 
      }


     /* registros?registros.content.map(record => (
        <Card key={record.id} style={{ marginBottom: '16px', backgroundColor: '#f5f5f5' }}>
          <p><strong>Nome:</strong> {record.nome}</p>
          <p><strong>Tags:</strong> {record.tagNames && record.tagNames.length > 0 ? record.tagNames.join(', ') : "Nenhuma tag"}</p>
          <p><strong>Telefone:</strong> {formatarTelefone(record.telefone)}</p>
          <p><strong>Origem:</strong> {formatarTelefone(record.telOrigem)}</p>
          <p><strong>Última Interação:</strong> {format(new Date(record.ultimaInteracao), "dd/MM/yyyy HH:mm")}</p>
          <Button onClick={() => openTagModal(record.id)}>Ações</Button>
        </Card>
      )) : ''*/
    }

      
    
  

  return (
    <Container>
      <Row
        gutter={16}
        style={{
          justifyContent: "center",
          marginTop: "110px",
          marginBottom: "80px",
          width:"100%"
        }}
      >
        <Col xs={24} sm={12} style={{ textAlign: "center" }}>
          
          <Form.Item>
            <Select
              style={{ width: "200px" }}
              placeholder="Selecione uma linha..."
              value={selectedInstancia}
              onChange={handleInstanciaChange}
              loading={loading}
            >
              <Option key={'todos'} value={'TODOS'}>{'       Todos       '}</Option>
              {instancias.map((opcao) => (
                <Option key={opcao.id} value={opcao.tel} >
                  {formatarTelefone(opcao.tel)}
                </Option>
              ))}
            </Select>
          </Form.Item> 
        </Col>
        <Col xs={24} sm={24} style={{ textAlign: "center" }}>
          <Card style={{ width: "100%" }}>
            <Form
              name="basic"
              layout="horizontal"
              initialValues={{ remember: true }}
              form={form1}
              onFinish={async (dados) => {
                try {
                  form2.resetFields(['buscar']); 
                  console.log(dados);
                  setLoading(true);
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                  };
                  setPagina(0);
                  const response1 = await axiosInstance.get(
                    `https://deliverypdv.com.br/v1/contatos/inativosTags/${selectedInstancia.toLowerCase()}/${idLoja}/${dados.dias?dados.dias:0}?page=${0}&size=${20}`,
                    options
                  );

                  const registrosAux = [];
                  response1.data.content.map((data) => {
                    registrosAux.push({
                      key: data.id,
                      ...data
                    });
                  });
                  setRegistros({ 
                    content: registrosAux,
                    totalElements: response1.data.totalElements
                  });

                  setPagination({
                    current: response1.data.pageable.pageNumber + 1,
                    pageSize: response1.data.pageable.pageSize,
                    total: response1.data.totalElements,
                  });
                  setArrayContatos([]);
                  setSelectedRowKeys([]);
                  setLoading(false);
                  setTextoSelecao(`e Sem interação há ${dados.dias?dados.dias:0} dia(s)`);
                  setBuscaDias(dados.dias);
                  setNomeOuTelefone('');
                  setBuscarTags('');


                } catch (error) {
                  console.error('Erro ao chamar a API:', error.status);
                  if (error.status === 404) {
                    console.error(`${error.status} Instancia não encontrada`);
                  }
                  setLoading(false);
                }
              }}
              onFinishFailed={(errorInfo) => { console.log('Failed:', errorInfo); }}
              autoComplete="off"
            >
              <Col xs={24} sm={24} style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                <Form.Item
                  name="dias"
                >
                  <InputNumber
                    placeholder="Não Falo há"
                    style={{ width: '100%' }}
                    rules={[{ required: true, message: 'Por favor, insira um número' }]}
                  />
                </Form.Item>
                <Button type="primary" style={{backgroundColor:'#000' }} loading={loading}  htmlType="submit">Consultar</Button>
              </Col>
            </Form>

            <Form
              name="basic"
              layout="horizontal"
              form={form2}
              initialValues={{ remember: true }}
              onFinish={async (dados) => {
                if(dados.buscar){
                  setTelefoneOuNomeTodos(dados.buscar);
                try {
                  form1.resetFields(['dias']); 
                  console.log(dados);
                  setLoading(true);
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                  };
                  setPagina(0);
                  const response1 = await axiosInstance.get(
                    `https://deliverypdv.com.br/v1/contatos/pegarTelOuNomeeTags/${selectedInstancia.toLowerCase()}/${idLoja}/${dados.buscar}`,
                    options
                  );
                  const registrosAux = [];
                  response1.data.content.map((data) => {
                    registrosAux.push({
                      key: data.id,
                      ...data
                    });
                  });
                  setRegistros({ 
                    content: registrosAux,
                    totalElements: response1.data.totalElements
                  });



                  setPagination({
                    current: response1.data.pageable.pageNumber + 1,
                    pageSize: response1.data.pageable.pageSize,
                    total: response1.data.totalElements,
                  });

                  setBuscaDias('');
                  setBuscarTags('');
                  setNomeOuTelefone(dados.buscar);
                  setArrayContatos([]);
                  setSelectedRowKeys([]);
                  setLoading(false);
                  setTextoSelecao(dados.buscar? `e que contém a expressão "${dados.buscar}"`:'');
                  
                } catch (error) {
                  console.error('Erro ao chamar a API:', error.status);
                  if (error.status === 404) {
                    console.error(`${error.status} Instancia não encontrada`);
                    setRegistros({ 
                      content: [],
                      totalElements: 0
                    });
                  }
                  setLoading(false);
                }
              }
              }}
              onFinishFailed={(errorInfo) => { console.log('Failed:', errorInfo); }}
              autoComplete="off"
            >
              <Col xs={24} sm={24} style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                <Form.Item
                  name="buscar"
                >
                  <Input
                    placeholder="Nome/Telefone" 
                    style={{ width: '100%' }} 
                  />
                </Form.Item>
                <Button
                  style={{backgroundColor:'#000' }}
                  type={'primary'}
                  htmlType={'submit'}
                  loading={loading}
                >
                  Consultar
                </Button>
              </Col>
            </Form>

            <Form
              name="basic"
              layout="horizontal"
              form={form3}
              initialValues={{ remember: true }}
              onFinish={async (dados) => {
                if(dados.nomeTags){
                  setBuscarTags(dados.nomeTags);
                try {
                  form3.resetFields(['nomeTag']); 
                  console.log(dados);
                  setLoading(true);
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                  };
                  setPagina(0);
                  const response1 = await axiosInstance.get(
                    `https://deliverypdv.com.br/v1/contatos/todosOrigemTags/${selectedInstancia.toLowerCase()}/${idLoja}/${dados.nomeTags}`,
                    options
                  );
                  const registrosAux = [];
                  response1.data.content.map((data) => {
                    registrosAux.push({
                      key: data.id,
                      ...data
                    });
                  });
                  setRegistros({ 
                    content: registrosAux,
                    totalElements: response1.data.totalElements
                  });



                  setPagination({
                    current: response1.data.pageable.pageNumber + 1,
                    pageSize: response1.data.pageable.pageSize,
                    total: response1.data.totalElements,
                  });

                  setBuscaDias('');
                  setNomeOuTelefone('');
                  setBuscarTags(dados.nomeTags);
                  setArrayContatos([]);
                  setSelectedRowKeys([]);
                  setLoading(false);
                  setTextoSelecao(dados.nomeTags? `e que contém a expressão "${dados.nomeTags}"`:'');
                  
                } catch (error) {
                  console.error('Erro ao chamar a API:', error.status);
                  if (error.status === 404) {
                    console.error(`${error.status} Instancia não encontrada`);
                    setRegistros({ 
                      content: [],
                      totalElements: 0
                    });
                  }
                  setLoading(false);
                }
              }
              }}
              onFinishFailed={(errorInfo) => { console.log('Failed:', errorInfo); }}
              autoComplete="off"
            >
              <Col xs={24} sm={24} style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'center' }}>
                <Form.Item
                  name="nomeTags"
                >
                  <Input
                    placeholder="Tags" 
                    style={{ width: '100%' }} 
                  />
                </Form.Item>
                <Button
                  style={{backgroundColor:'#000' }}
                  type={'primary'}
                  htmlType={'submit'}
                  loading={loading}
                >
                  Consultar
                </Button>
              </Col>
            </Form>










          </Card> 
          <Card style={{ width: "100%" }}>
            <Col xs={24} sm={24} style={{ textAlign: 'center' }}>
            <strong> Seleção: {`${selectedInstancia == 'TODOS'? selectedInstancia: selectedInstancia ? formatarTelefone(selectedInstancia):''} ${textoSelecao} `}</strong>
            </Col>
            <Col xs={24} sm={24} >

              <div>
              <Modal
                title="Confirmação"
                visible={visibleExcluir}
                onOk={() => {
                  console.log("Operação efetivada");
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                    data: arrayContatos 
                  };
                  setLoading(true);
                  console.log(arrayContatos);
                  
                  axiosInstance.delete(
                    `https://deliverypdv.com.br/v1/contatos`,
                    options   
                  ).then((response) => {
                    console.log(response.status);
              
                    if(response.status == 200){
                      notification.success({
                        message: "Sucesso",
                        description: "Exclusão feita!",
                      }); 
                      setArrayContatos([]);
                      setSelectedRowKeys([]);
                      buscarContatos(selectedInstancia);
                    }else{
                      notification.error({
                        message: "Falha",
                        description: "Falha na exclusão!",
                      });
                      setLoading(false);  
                    }

                  }).catch((error) => {
                    console.error('Error:', error);
                    notification.error({
                      message: "Falha",
                      description: "Falha na exclusão!",
                    });
                    setLoading(false);  
                  });
                  setVisibleExcluir(false);
                }}
                onCancel={() => setVisibleExcluir(false)}
                okText="Confirmar"
                cancelText="Cancelar"
              >
                <p>Deseja Mesmo Excluir?</p>
              </Modal>
              <Modal
                title="Confirmação"
                visible={visibleBloquear}
                onOk={() => {
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                  };
                  setLoading(true);
                  console.log(arrayContatos);
                  
                  axiosInstance.put(
                    `https://deliverypdv.com.br/v1/contatos/bloquear`,arrayContatos,
                    options   
                  ).then((response) => {
                    console.log(response.status);
              
                    if(response.status == 200){
                      notification.success({
                        message: "Sucesso",
                        description: "Bloqueio feito!",
                      }); 
                      setArrayContatos([]);
                      setSelectedRowKeys([]);
                      buscarContatos(selectedInstancia);
                    }else{
                      notification.error({
                        message: "Falha",
                        description: "Falha no bloqueio!",
                      });
                      setLoading(false);  
                    }

                  }).catch((error) => {
                    console.error('Error:', error);
                    notification.error({
                      message: "Falha",
                      description: "Falha no bloqueio!",
                    });
                    setLoading(false);  
                  });
                  setVisibleBloquear(false);
                }}
                onCancel={() => setVisibleBloquear(false)}
                okText="Confirmar"
                cancelText="Cancelar"
              >
                <p>Deseja Mesmo Bloquear?</p>
              </Modal>
              <Modal
                title="Configurar Tags"
                visible={isModalOpen}
                onOk={saveTags}
                onCancel={() => setIsModalOpen(false)}
                width={600}
              >
                {isLoadingTags ? (
                  <p>Carregando tags...</p>
                ) : (
                  <Row gutter={[16, 16]}>
                    {allTags.map((tag) => (
                      <Col xs={24} sm={12} md={12} lg={8} xl={6} key={tag.id}>
                        <Checkbox
                          checked={checkedTags.includes(tag.id)}
                          onChange={() => handleTagCheck(tag.id)}
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {tag.nome}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                )}
              </Modal>


              <Modal
                title="Add Grupo"
                visible={isModalOpenGrupos}
                onOk={() => setIsModalOpenGrupos(false)}
                onCancel={() => setIsModalOpenGrupos(false)}
                width={1200}
              >
            
                  <ContatosGrupos arrayContatos={arrayContatos} selectedInstancia={selectedInstancia}></ContatosGrupos>
                
              </Modal>
              </div>



              <Container2>
              <Button type="primary"  style={{backgroundColor:'#000' }} loading={loading} htmlType="Button" onClick={async () => {
                console.log('limpar seleção');
                setArrayContatos([]);
                setSelectedRowKeys([]);
                setRegistros([]);
                setSelectedInstancia(null);
                setPagination({
                  current: 1,
                  pageSize: 20,
                  total: 0,
                });
                form1.resetFields(['dias']); 
                form2.resetFields(['buscar']); 
                form3.resetFields(['nomeTag']);
                setTextoSelecao('');            
              }}> Limpar Seleção
              </Button>
              <Button type="primary"      
                    style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    color: 'white',
                    opacity:  arrayContatos.length == 0? 0.5 : 1 ,
                    pointerEvents: arrayContatos.length == 0? true : false ? 'none' : 'auto', 
                  }}  
                    disabled={arrayContatos.length == 0? true : false}  loading={loading} htmlType="Button" onClick={async () => {
                      setVisibleExcluir(true);
        
              }}> Excluir Contatos 
              </Button>

              <Button type="primary"      
                    style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    color: 'white',
                    opacity:  arrayContatos.length == 0? 0.5 : 1 ,
                    pointerEvents: arrayContatos.length == 0? true : false ? 'none' : 'auto', 
                  }}  
                    disabled={arrayContatos.length == 0? true : false}  loading={loading} htmlType="Button" onClick={async () => {
                      setVisibleBloquear(true);
              }}> Bloquear Contatos 
              </Button>
              
              <Checkbox 
                onChange={onChangeTodos} 
                style={{ 
                  border: '1px solid #000', 
                  borderRadius: '4px', 
                  padding: '8px', 
                  display: 'flex',
                  backgroundColor: '#000',
                  color: '#fff',
                  flexDirection: 'row-reverse',
                  justifyContent: 'center',
                }}
              >
                Todos
              </Checkbox>

              <Button type="primary"      
                    style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    color: 'white',
                  }}  

                  onClick={async () => {
                    setIsModalOpenGrupos(true);
      
               }}
            > Grupo Add 
              </Button>
              </Container2>
   
            </Col>
            <strong>Aviso: As funções abaixo afetam apenas os contatos na lista deste aplicativo e, em nenhum momento, vão afetar os contatos reais no telefone/WhatsApp do proprietário.</strong>
          </Card> 
          <br />
          <Card>


      {isMobile ? (
        <div>{renderMobileCards()}</div>
      ) : (
        <Card>
          <Table
            rowSelection={checkboxTodos ? null : { selectedRowKeys, onChange: onSelectChange }}
            columns={columns}
            dataSource={registros.content}
            loading={loading}
            pagination={!checkboxTodos ? pagination : null}
            onChange={handleTableChange}
          />
        </Card>
      )}









          </Card>
          <Card>
          <strong>{arrayContatos.length} contato(s) selecionado(s) do total de {pagination.total} contato(s) ativo(s)</strong>
          </Card>
        </Col>
      </Row>
    </Container>
    
  );
};



export default CrudMensagem;

const Container = styled.div`
  padding: 20px;
`;
const Container2 = styled.div`
  padding: 20px;
  display: grid;
  gap: 8px;

    @media (min-width: 600px) {
        padding: 20px;
        gap: 8px;
        padding-left: 8px;
        padding-right: 8px; 
        display: flex; flex-direction: row;
        align-items: baseline;
        justify-content: center;
  }




  @media (max-width: 600px) {
    grid-template-columns: 1fr; // Ajusta para 1 coluna em telas menores
  }
`;


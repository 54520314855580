import React, { useEffect, useState } from "react";
import botApiUrl from "../../../botApiUrl";
import { message } from "antd";

const ContatosGrupoDiv = ({ record, options }) => {
  const [contatosDoGrupo, setContatosDoGrupo] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessToken = window.localStorage.getItem("accessToken");

  useEffect(() => {
    // Função assíncrona para buscar os dados
    const fetchContatosDoGrupo = async () => {
      try {
        const response = await botApiUrl.get(
          `/v1/contatos-do-grupo/listarTodosContatosDoGrupo/${record.id}`,
          options
        );
        setContatosDoGrupo(response.data); // Armazena os dados na variável de estado
      } catch (error) {
        console.error("Erro ao buscar os contatos do grupo:", error);
      } finally {
        setLoading(false); // Define como não carregando após a requisição
      }
    };

    fetchContatosDoGrupo();
  }, [record.id, options]); // Reexecuta se `record.id` ou `options` mudar

  // Renderização condicional enquanto carrega
  if (loading) {
    return <p>Carregando contatos...</p>;
  }



  const removerContato = async (idContato) => {
    console.log('removerContato');

    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    const idContatoRemover = [];
    idContatoRemover.push(idContato);

    const body = { comando: JSON.stringify({ comando_string: "REMOVER_DO_GRUPO", parametros_string: [record.id, record.instancia, idContatoRemover] }), nodeServer:`${window.localStorage.getItem("server")}` };

    const resp = await botApiUrl.post(
    `v1/pilha-comandos/pushComNodeServer`, body,
      options
    );
    console.log(resp.status);
    if (resp.status == 201) {
      message.success('Removendo do Grupo!');
    } else {
      message.error('Houve uma falha ao tentar remover!');
    }
  };




  const formatarTelefone = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.startsWith('55') && (cleaned.length === 12 || cleaned.length === 13)) {
      const numberWithoutCountryCode = cleaned.substring(2);
      if (numberWithoutCountryCode.length === 11) {
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{5})(\d{4})$/, '+55 ($1) $2-$3');
      } else if (numberWithoutCountryCode.length === 10) {
        return numberWithoutCountryCode.replace(/^(\d{2})(\d{4})(\d{4})$/, '+55 ($1) $2-$3');
      } else {
        return phoneNumber;
      }
    } else {
      return phoneNumber;
    }
  };




  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h3 style={{ borderBottom: "2px solid #ccc", paddingBottom: "10px" }}>Contatos do Grupo</h3>
      {contatosDoGrupo.length > 0 ? (
        contatosDoGrupo.map((contato) => (
          <div
            key={contato.id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "10px",
              marginBottom: "10px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <div>
              <p style={{ margin: "0" }}>
                <strong>Nome:</strong> {contato.nome}
              </p>
              <p style={{ margin: "0" }}>
                <strong>Telefone:</strong> {formatarTelefone(contato.telefone)}
              </p>
            </div>
            <button
              onClick={() => removerContato(contato.id)}
              style={{
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                padding: "8px 12px",
                cursor: "pointer",
              }}
            >
              Remover
            </button>
          </div>
        ))
      ) : (
        <p style={{ textAlign: "center", color: "#666" }}>Nenhum contato encontrado.</p>
      )}
    </div>
  );
};

export default ContatosGrupoDiv;

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  faPencilAlt,
  faKey,
  faTrash,
  faLockOpen,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { logout } from "../../../Token/actions";
import { styled } from "styled-components";
import camuflado from "../../../fundo.webp";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Skeleton,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory, useParams } from "react-router-dom";
import axiosInstance from "../../../axiosInterceptor";
import { Modal as AntdModal } from "antd";

const Modal = ({ isOpen, onClose, onSave, chaveTexto }) => {
  const [loadingModal, setLoadingModal] = useState(false);

  const accessToken = window.localStorage.getItem("accessToken");
  const chave = window.localStorage.getItem("chaveCofre");

  return (
    <AntdModal
      visible={isOpen}
      onCancel={onClose}
      footer={null} // Remova o array de botões do rodapé
    >
      <Form
        name="passwordForm"
        onFinish={(values) => {
          console.log(values);

          const data2 = {
            chave: values.chave,
          };
          setLoadingModal(true);
          const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };

          axiosInstance
            .post(
              `https://techfood-api.herokuapp.com/v1/usuario/validarChave`,
              data2,
              options
            )
            .then((response) => {
              console.log(response.data);
              if (response.data === true) {
                setLoadingModal(false);
                notification.success({
                  message: "Sucesso",
                  description: "Chave salva com sucesso!",
                });

                window.localStorage.setItem("chaveCofre", values.chave);
              } else {
                setLoadingModal(false);
                notification.error({
                  message: "Erro",
                  description:
                    "Não foi possível salvar a chave, verifique se está correta e tente novamente!",
                });
              }

              setTimeout(() => {
                onClose();
              }, 300);
            });
        }}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Form.Item
          label="Chave"
          name="chave"
          initialValue={chave}
          rules={[
            {
              required: true,
              message: "A chave deve conter 6 digitos",
              max: 6,
              min: 6,
            },
          ]}
        >
          <Input
            disabled={chave ? true : false}
            style={{
              // background: chave ? "#b4b5b8" : "",
              // backgroundColor: chave ? "#484848" : "",
              color: chave ? "#000" : "#000",
            }}
            maxLength="6"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingModal}
            style={{
              width: "100%",
              marginRight: 8,
              //backgroundColor: chave ? "#b4b5b8" : "",
            }}
            disabled={chave ? true : false}
          >
            SALVAR
          </Button>
          <Button type="default" onClick={onClose} style={{ width: "100%" }}>
            CANCELAR
          </Button>
        </Form.Item>
      </Form>
    </AntdModal>
  );
};

const VisualizarPerfil = ({ onTabChange, recarregarDados }) => {
  const dispatch = useDispatch();
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const chave = window.localStorage.getItem("chaveCofre");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [usuario, setUsuario] = useState("");
  const [cr, setCR] = useState("");
  const [md5, setMd5] = useState("jmsdjrfgjmnçzojegehhnhnouhsoç");
  const [telefone, setTelefone] = useState("");
  const [cpf, setCpf] = useState("");
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const marginBottom = isMobile ? "0" : "90px";

  const [excluirModalVisible, setExcluirModalVisible] = useState(false);
  const [secondExcluirModalVisible, setSecondExcluirModalVisible] =
    useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const handleChave = () => {
    setModalOpen(true);
  };

  const handleButtonEditar = () => {
    onTabChange("subTab2");
  };
  const handleButtonTrocarSenha = () => {
    onTabChange("subTab3");
  };

  const handleEditar = () => {};

  const handleAlterarSenha = () => {};

  const formatarCpf = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const formatarTelefone = (telefone) => {
    if (telefone.length === 10) {
      return telefone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    } else {
      return telefone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }
  };

  const handleExcluir = () => {
    const confirmed = window.confirm(
      "Tem certeza que deseja excluir a conta?\nEsta ação não pode ser desfeita."
    );

    if (confirmed) {
      const confirmed2 = window.confirm(
        "Esta é sua última chance. Deseja continuar e excluir permanentemente sua conta?"
      );

      if (confirmed2) {
        realizarExclusao();
      }
    }
  };

  const realizarExclusao = async () => {
    try {
      try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };

        const response = await axiosInstance.delete(
          "https://auth2-tech.herokuapp.com/v1/usuarios/delete",
          options
        );
        console.log(response);
        if (response.status === 24498498) {
          const response1 = await axiosInstance.delete(
            `https://app-photo-api-bkp-cdbfbd0a41b0.herokuapp.com/v1/usuario/${idUser}`,
            options
          );
          window.localStorage.removeItem("accessToken");
          window.localStorage.removeItem("idUser");

          history.push("/");
          window.location.reload();
        }
      } catch (error) {
        if (error.response) {
          setLoading(false);
          return;
        } else {
          setLoading(false);
          return;
        }
      }
    } catch (error) {
      console.error("Erro ao excluir a conta:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
     /* try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
        const response = await axiosInstance.get(
          "https://techfood-api.herokuapp.com/v1/usuario/" + idUser,
          options
        );
        console.log(response.data)
        setCR(response.data.cr);
        setCpf(response.data.cpf);
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setLoading(false);
      }*/

      try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
        const response = await axiosInstance.get(
          "https://auth2-tech.herokuapp.com/v1/usuarios/" + idUser,
          options
        );
        setNome(response.data.nome);
        setEmail(response.data.email);
        setTelefone(response.data.fone);
        setUsuario(response.data.usuario);
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [idUser, accessToken, recarregarDados]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            width: "80%",
            marginTop: "140px",
            marginBottom: marginBottom,
          }}
        >
          {loading ? (
            <Skeleton active /> // Exibe o esqueleto de carregamento enquanto os dados são buscados
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  Minha Conta
                  <Button
                    icon={<FontAwesomeIcon icon={faPencilAlt} />}
                    type="primary"
                    onClick={handleButtonEditar}
                  ></Button>
                </h1>
              </div>
              <Row>
                <Col span={24}>
                  <h3>Usuário:</h3>
                  <span>{usuario}</span>
                  <h3>Nome:</h3>
                  <span>{nome}</span>
                  <h3>Telefone:</h3>
                  <span>{telefone}</span>
                  <h3>E-mail:</h3>
                  <span>{email}</span>
                </Col>
                <br />

              </Row>
            </>
          )}
        </Card>
      </div>
      <Container>
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={(novoTexto) => {
            console.log("Texto salvo:", novoTexto);
          }}
          chaveTexto={chave}
        />
      </Container>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 82vh;
  margin: 0;
  padding: 0;
`;

const ActionButton = styled.button`
  padding: 10px 10px;
  margin-left: 8px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
`;
export default VisualizarPerfil;

import React, { useState, useEffect, useCallback } from "react";
import camuflado from "../../../fundo.webp";
import styled from "styled-components";
import { Button, Input, Form, Row, Col, Card, message, Modal, Select } from 'antd';
import TextArea from "antd/es/input/TextArea";
import axiosInstance from "../../../axiosInterceptor";
import UploadImg from "../../../UploadImg";
import botApiUrl from "../../../botApiUrl";
import { faBan, faCircleInfo, faClock, faMessage, faRobot, faSeedling } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AgendamentoMensagem from "./AgendamentoMensagem";
import dayjs from 'dayjs';


const MensagemAgendada = ({ arrayContatos, pagination }) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [loading, setLoading] = useState(false);

  const [messageContent, setMessageContent] = useState('');
  const [idLoja, setIDLoja] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [instancias, setInstancias] = useState([]);

  const [mensagem, setMensagem] = useState('');
  const [telefonePrincipal, setTelefonePrincipal] = useState('');
  const [clientIdPrincipal, setClientIdPrincipal] = useState('');

  const [textoEditado, setTextoEditado] = useState(false);

  const [recarregar, setRecarregar] = useState(false);
  const [arrayMensagens, setMensagens] = useState(null);
  const [selectedMessageID, setSelectedMessageID] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);


  const [recarregarMensagens, setRecarregaMensagens] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [temAgendamento, setTemAgendamento] = useState(false);
  const [contatosRecuperados, setContatosRecuperados] = useState([]);
  const [mensagemAlterada, setMensagemAlterada] = useState(false);
  

  const handleButtonClick = () => {
    if(isCardVisible){
      setSelectedDate(null);
      setSelectedTime(null);
    }
    setIsCardVisible(!isCardVisible);
  };



  const base64ToFile = (base64, filename, mimeType) => {
    console.log(base64);
    const arr = base64.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, { type: mimeType });
  };

  const buscarDados = async() => {
    setMensagens(null);
     setLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    try {
       
      const resp = await botApiUrl.get(
        `/v1/sessao/buscarPorIdLojaLogado/${idLoja}`,
        options
      );
      console.log(resp.data);
      setMensagens(resp.data);
      setLoading(false);

    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  

 /* useEffect(() => {
    try {
      if (idLoja && accessToken) {
        console.log('Buscando lista de mensagens');
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
        botApiUrl.get(
          `/v1/sessao/buscarPorIdLojaLogado/${idLoja}`,
          options
        ).then((resp) => {
          
          setSelectedMessage(null);
          setSelectedMessageID(null);
          setMensagens(resp.data);
          setLoading(false);
        }).catch((error) => {
          setLoading(false);
        });
      }
    } catch (error) {
      setLoading(false);
    }


  }, [accessToken, idLoja, recarregarMensagens]);*/



  useEffect(() => {
    if (!idUser) return;

    const fetchData = async () => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      if (!idLoja) {
        const resp = await axiosInstance.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options);
        setIDLoja(resp.data.id);
      }
    };
    fetchData();
  }, [idUser]);

  const handleTextAreaChange = (event) => {
    //setMessageContent(event.target.value);
    console.log(event.target.value);
    setMensagemAlterada(true);
    setTextoEditado(true);
  };

  const [form] = Form.useForm();

  const [form2] = Form.useForm();


  const { Option } = Select;



  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };



  const handleSelectedMessage = async (idMensagem) => {


    try {
      await resetAllForms();
      setLoading(true);
      setSelectedMessageID(idMensagem);

      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
  
      botApiUrl.get(
        `/v1/mensagens-instantaneas/${idMensagem}`,
        options
      ).then((resp) => {
        setSelectedMessage(resp.data);
        resetAllForms();
        console.log(resp.data);
        
      if (resp.data.mensagem) {
        form.setFieldsValue({ mensagem: JSON.parse(resp.data.mensagem).mensagem });
        const arrayImagensString = JSON.parse(resp.data.mensagem).imagem;
        console.log(arrayImagensString);
        if (arrayImagensString.length > 0) {
          const arrayImagens = arrayImagensString;
          console.log(arrayImagens);
          console.log(arrayImagens.length);
          const initialFileList = arrayImagens.map((image, index) => {
            const file = base64ToFile(image.base64, image.name, image.type);
            return {
              ...image,
              originFileObj: file,
  
            };
          });
          setFileList(initialFileList);
        }
      }


      if (resp.data.agendamento) {
        console.log('tem agendamento');
        console.log(resp.data.agendamento);
        const agendamento = JSON.parse(resp.data.agendamento);

        setSelectedDate(dayjs(agendamento.selectedDate));
        setSelectedTime(dayjs(agendamento.selectedTime));
        setTemAgendamento(true);
        setIsCardVisible(true);
      }else{
        setSelectedDate(null);
        setSelectedTime(null);
        setIsCardVisible(false);
        setTemAgendamento(false);
      }
        
      }).catch((error) => {
        setLoading(false);
      });
  


      botApiUrl.get(
        `/v1/contatos-mensagens-agendadas/${idMensagem}`,
        options
      ).then((resp) => {
      const idContatos = resp.data.map(item => item.idContato);
      console.log(idContatos);

      botApiUrl.post(
        `/v1/contatos/contatosPorID`,idContatos,
        options
      ).then((resp) => {

      console.log(resp.data);
      setContatosRecuperados(resp.data);
      }).catch((error) => {
        setLoading(false);
      });
  



      }).catch((error) => {
        setLoading(false);
      });
  

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }










    


  };



  const resetAllForms = async () => {
    form.resetFields();
    setFileList([]);
  };

  const handleOk = async () => {
    try {


      console.log(idLoja);
      if(!idLoja){
        return;
      }
    const values = await form2.validateFields();
      setLoading(true);
      // Enviar os dados para o backend
      console.log(values);
 
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

      const response = await botApiUrl
        .post(`/v1/mensagens-instantaneas`,
          {
            labelMensagem: values.nome,
            idLoja: `${idLoja}`,
          }, options);

          if(response.status == 200){
            const arrayAux = arrayMensagens;
            arrayAux.unshift(response.data);
            setMensagens(arrayAux);
            await resetAllForms();
            setSelectedMessageID(null);
            setSelectedMessage(null);
          }


            setVisible(false);
            setLoading(false);

      
          } catch (error) {
            setLoading(false);
            console.error('Validation Failed:', error);
          }


  };



  useEffect(() => {
    try {
      if (idLoja && accessToken) {
        
        console.log('Buscando lista de bots');
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };

        botApiUrl.get(
          `/v1/mensagens-instantaneas/getMensagensAgendadasPorLojaId/${idLoja}`,
          options
        ).then((resp) => {
          setMensagens(resp.data);
          setLoading(false);
        }).catch((error) => {
          setLoading(false);
        });
      }
    } catch (error) {
      setLoading(false);
    }


  }, [accessToken, idLoja]);



  const handleLimparAgendamento = async () => {
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    setLoading(true);
  
    const body = { comando: JSON.stringify({ comando_string: "ENVIAR_MSG_AGENDADA", parametros_string: [[`${selectedMessageID}`]] }) };
  
    try {
      const resp = await botApiUrl.put(
        `/v1/mensagens-instantaneas/removerAgendamento/comando/${selectedMessageID}`,body,
 
        options
      );
  
      if (resp.status === 200) {
        message.success('Agendamento limpo!');
        setTemAgendamento(false);
        setSelectedDate(null);
        setSelectedTime(null);
        setIsCardVisible(false);
      } else {
        message.error('Houve uma falha ao tentar limpar a mensagem.');
      }
    } catch (error) {
      message.error('Erro ao limpar o agendamento.');
    } finally {
      setLoading(false);
    }
  };
  

  const formatarTelefone = (telefone) => {
    const match = telefone.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return telefone; // Retorna o original caso não corresponda ao formato esperado
  };

  return (
    <Row
      style={{
        justifyContent: "center",
        marginTop: "110px",
        marginBottom: "80px",
      }}
    >
      <Col xs={24} sm={20} md={16} lg={10} xl={10}>


      <Card title="Mensagem Agendada" bordered={false} >
          <Col style={{ display: "flex", alignItems: "baseline", gap:'5px' }}>
            <>
              <Button
                type="primary"
                htmlType="button"
                style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
                  color: 'white',
                }}
                loading={loading}
                onClick={()=>{
                  showModal();
                  //tratarHorariosBot();
                }}
              >
                Criar Mensagem <FontAwesomeIcon icon={faMessage} style={{ marginLeft: 8 }} />
              </Button>
              <Modal
                visible={visible}
                title="Criar Mensagem"
                onCancel={handleCancel}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    Cancelar
                  </Button>,
                  <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Criar
                  </Button>,
                ]}
              >
                <Form form={form2} layout="vertical">
                  <Form.Item
                    name="nome"
                    label="Nome"
                    rules={[{ required: true, message: 'Por favor, insira o nome' }]}
                  >
                    <Input />
                  </Form.Item>
                </Form>
              </Modal>
            </>

            <Button
              type="primary"
              htmlType="button"
              style={{
                backgroundColor: 'black',
                borderColor: 'black',
                color: 'white',
                opacity: !selectedMessageID  ? 0.5 : 1,
                //pointerEvents: !selectedMessageID ? 'none' : 'auto',
              }}
              onClick={() => {
                if(selectedMessageID){
                  Modal.confirm({
                    title: 'Você tem certeza que deseja excluir mensagem?',
                    content: 'Esta ação não pode ser desfeita.',
                    okText: 'Sim, excluir',
                    okType: 'danger',
                    cancelText: 'Cancelar',
                    onOk() {
                      const options = {
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + accessToken,
                        }
                      };
                      setLoading(true);
        
                try {
                  botApiUrl.delete(
                    `/v1/mensagens-instantaneas/${selectedMessageID}`,
                    options
                  ).then((response) => {
   
                    if (response.status === 204) {
                      message.success('Mensagem Excluída!');
                      resetAllForms();
                      setSelectedMessageID(null);
                      setSelectedMessage(null);
      

                      const arrayAux = arrayMensagens.filter(mensagem => mensagem.id !== selectedMessageID);

                     setMensagens(arrayAux);
                  
                    } else {
                      message.error('Falha ao Excluir a Mensagem!');
                    }
                  }).finally(() => {
                    setLoading(false);
                  });
                } catch (error) {
                  setLoading(false);
                }

                    },
                  });
                }
            
              }}
               disabled={!selectedMessageID}
            >
              Excluir Mensagem <FontAwesomeIcon icon={faMessage} style={{ marginLeft: 8 }} />
            </Button>



          </Col>
          <Form.Item>
            <Select
              style={{ width: "200px",marginTop:'10px' }}
              placeholder="Selecione uma Mensagem..."
              value={selectedMessageID}
              onChange={handleSelectedMessage}
              loading={loading}
              disabled={arrayMensagens ? arrayMensagens.length === 0 ? true : false : true} 
              showSearch 
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {arrayMensagens?.map((opcao) => (
                <Option key={opcao.id} value={opcao.id}>
                  {opcao.labelMensagem}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Card>









        <Card title="Salvar Mensagem" bordered={false}>
          <Form layout="vertical" form={form} onFinish={async (dados) => {
            if (dados.mensagem != undefined) {
              if (dados.mensagem.trim() === '') {
                message.error('A mensagem não pode estar vazia');
              } else {
                const arrayDeImagens = [];
                console.log(fileList);
              
                fileList.map((imagem) => {
                  arrayDeImagens.push(JSON.stringify(imagem));
                });

                const contatosAux =  arrayContatos.length > 0 
                ? arrayContatos 
                : contatosRecuperados
                    .filter(contato => contato.id !== "") 
                    .map(contato => contato.id);



                const body = {
                  id: `${selectedMessageID}`,
                  idLoja: `${idLoja}`,
                  labelMensagem:selectedMessage.labelMensagem,
                  mensagem : JSON.stringify({mensagem: dados.mensagem,
                  imagem: fileList
                 }),
                 contatos: contatosAux,
                 grupos: [],
                  agendamento:selectedDate?JSON.stringify({selectedDate:selectedDate,selectedTime:selectedTime}):''
                };

                try {
                  setLoading(true);
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                  };

                const resp =  await botApiUrl.put(
                    `/v1/mensagens-instantaneas`, body,
                    options
                  )
                    console.log(resp.data);
                    if (resp.status == 200) {
                      message.success('Mensagem salva!');
                      setSelectedMessage(resp.data);
                      
                      setMensagemAlterada(false);





                      botApiUrl.get(
                        `/v1/contatos-mensagens-agendadas/${resp.data.id}`,
                        options
                      ).then((resp) => {
                      const idContatos = resp.data.map(item => item.idContato);
                      console.log(idContatos);
                
                      botApiUrl.post(
                        `/v1/contatos/contatosPorID`,idContatos,
                        options
                      ).then((resp) => {
                
                      console.log(resp.data);
                      setContatosRecuperados(resp.data);
                      }).catch((error) => {
                        setLoading(false);
                      });
                  
                
                      }).catch((error) => {
                        setLoading(false);
                      });




















      
                      
                      console.log(selectedMessage);
                      setLoading(false);
                    }
             

                } catch (error) {
                  console.error('Erro ao chamar a API:', error.status);
                  if (error.status === 404) {
                    console.error(`${error.status}`);
                    message.error('Erro ao gravar a mensagem!');
                  }
                  setLoading(false);
                }
              }
            } else {
              message.error('A mensagem não pode estar vazia');
            }
          }}>
            <Form.Item label="Sua Mensagem" name="mensagem">
              <TextArea
                rows={4}
                placeholder="Digite sua mensagem aqui..."
                onChange={handleTextAreaChange}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit"
                style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
                  color: 'white',
                  opacity: !selectedMessageID ? 0.5 : 1,
                 // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
                }}
                disabled={!selectedMessageID ? true : false}
                loading={loading}
              >
                Salvar Mensagem
              </Button>

              <Button type="primary" htmlType="button"
                style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
                  color: 'white',
                  opacity: !selectedMessage?.mensagem ? 0.5 : 1,
                 // opacity: !mensagem  ? 0.5 : 1,
                 // pointerEvents: !mensagem  ? 'none' : 'auto',
                }}
          
                loading={loading}
                disabled={!selectedMessage?.mensagem || mensagemAlterada ? true : false}
                onClick={async () => {
                  console.log(selectedMessageID);
           
                  setLoading(true);
                  const options = {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + accessToken,
                    },
                  };

                  const body = { comando: JSON.stringify({ comando_string: "ENVIAR_MSG_AGENDADA", parametros_string: [`${selectedMessageID}`,'20','60'] }), nodeServer:`${window.localStorage.getItem("server")}` };
                  const resp = await botApiUrl.post(
                  `/v1/pilha-comandos/pushComNodeServer`, body,
                 // `http://localhost:3010/v1/pilha-comandos/pushComNodeServer/`, body,
                    options
                  );
                  console.log(resp.status);
                  if (resp.status == 201) {
                    message.success('Mensagem enviada!');
                  } else {
                    message.error('Houve uma falha ao tentar enviar a mensagem.');
                  }
                  setLoading(false);
                }}
              >
                Enviar Mensagem Agora
              </Button>
              <Button type="primary"  style={{
                backgroundColor: temAgendamento ? 'red': 'black',
                borderColor: temAgendamento ? 'red': 'black',
                color: 'white',
                opacity: !selectedMessageID ? 0.5 : 1,
              // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
              }}
              disabled={mensagemAlterada}  
              onClick={temAgendamento? handleLimparAgendamento : handleButtonClick}>
              {temAgendamento? 'Cancelar Agendamento' : 'Envio Agendado'}
              <FontAwesomeIcon
                icon={temAgendamento? faBan : faClock}
                style={{ marginLeft:'10px'}}
              />
              </Button>
            </Form.Item>

            <UploadImg fileList={fileList} setFileList={setFileList} ></UploadImg>
            <AgendamentoMensagem handleLimparAgendamento={handleLimparAgendamento} setTemAgendamento={setTemAgendamento} temAgendamento={temAgendamento} selectedMessageID={selectedMessageID} setLoading={setLoading} setSelectedDate={setSelectedDate} selectedDate={selectedDate}  setSelectedTime={setSelectedTime} selectedTime={selectedTime} selectedMessage={selectedMessage} setSelectedMessage={setSelectedMessage} idLoja={idLoja} isCardVisible={isCardVisible} setIsCardVisible={setIsCardVisible}   ></AgendamentoMensagem>


            <Card>
            {contatosRecuperados.length > 0 ? (
              <div style={{ maxHeight: '200px', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px' }}>
                <strong>Contatos:</strong>
                <p>
                  {contatosRecuperados.map(contato => 
                    `${contato.nome} ${formatarTelefone(contato.telefone)}`
                  ).join('; ')}
                </p>
              </div>
            ) : (
              <strong>Não temos contatos salvos.</strong>
            )}
                  
        </Card>

            <Card>
          <strong>{arrayContatos.length} contato(s) selecionado(s) do total de {pagination.total} contato(s) ativo(s)</strong>
        </Card>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

const Container = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #484848;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-image: url(${camuflado});
`;

export default MensagemAgendada;

import React, { useState } from 'react';
import { Button, Card, DatePicker, message, TimePicker } from 'antd';
import moment from 'moment';
import axiosInstance from '../../../axiosInterceptor';
import botApiUrl from '../../../botApiUrl';

const AgendamentoMensagem = ({setTemAgendamento, temAgendamento, selectedMessageID, setLoading, setSelectedDate, selectedDate, setSelectedTime, selectedTime, isCardVisible, setIsCardVisible}) => {
  const accessToken = window.localStorage.getItem("accessToken");


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  

  

  const handleAgendamento = async () => {
    if (selectedDate && selectedTime) {

      const date = selectedDate.toDate(); // Converte para um objeto Date
      const time = selectedTime.toDate(); // Converte para um objeto Date

      // Combina a data e hora
      const combinedDateTime = new Date(date);
      combinedDateTime.setHours(time.getHours());
      combinedDateTime.setMinutes(time.getMinutes());
      combinedDateTime.setSeconds(0); // Definindo segundos para 0
      combinedDateTime.setMilliseconds(0); // Definindo milissegundos para 0

      // Converte para formato UTC
      const utcDateTime = combinedDateTime.toISOString(); // Converte para formato UTC
      
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };

        const body = { comando: JSON.stringify({ comando_string: "ENVIAR_MSG_AGENDADA", parametros_string: [`${selectedMessageID}`,'20','60'] }),"data":`${utcDateTime}`, nodeServer:`${window.localStorage.getItem("server")}` };
        const resp = await botApiUrl.post(
        `v1/pilha-comandos/pushAgendadoComNodeServer`, body,
          options
        );
        console.log(resp.status);
        if (resp.status == 201) {
          message.success('Mensagem enviada!');
          setTemAgendamento(true);
          const body2 = { agendamento: selectedDate?JSON.stringify({selectedDate:selectedDate,selectedTime:selectedTime}):null};
          
          const resp2 = await botApiUrl.put(
            `/v1/mensagens-instantaneas/atualizarAgendamento/${selectedMessageID}`, body2,
              options
            );

              console.log(resp2.data);
    

        } else {
          message.error('Houve uma falha ao tentar enviar a mensagem.');
        }
        setLoading(false);
      


    } else {
      console.log('Por favor, selecione data e hora');
    }


  };

  return (
    <>
    <div>
      {isCardVisible && (
        <Card title="Agendar Envio" style={{ marginTop: 16 }}>
          <div>
            <DatePicker 
              onChange={handleDateChange} 
              value={selectedDate} 
              style={{ marginRight: 16 }} 
              placeholder="Selecione a data"
              format="DD/MM/YYYY"
            />
            <TimePicker 
              onChange={handleTimeChange} 
              value={selectedTime} 
              format="HH:mm" 
              placeholder="Selecione a hora"
            />
  

            <Button type="primary"  style={{
                backgroundColor: 'black',
                borderColor: 'black',
                color: 'white',
                opacity: temAgendamento || !selectedMessageID ||  !(selectedDate && selectedTime)? 0.5 : 1,
              // pointerEvents: arrayContatos.length == 0 ? 'none' : 'auto',
              }
             }
            disabled={temAgendamento || !selectedMessageID || !(selectedDate && selectedTime)}
              onClick={handleAgendamento}>
              Confirmar Agendamento
            </Button>
            </div>
        </Card>
      )}
    </div>
    </>
  );
};

export default AgendamentoMensagem;

import { message } from "antd";
import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    // Retorna a resposta normalmente se não houver erros
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Se o status for 401, trata a autenticação
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("idUser");
        window.location.reload();
      } else if (error.response.status === 500) {
        message.error("Erro 500: Problema interno no servidor.");
      }
    } else {
      message.error("Erro sem resposta do servidor:", error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";

import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Skeleton,
} from "antd";
import axiosInstance from "../../../axiosInterceptor";

const Perfil = ({ onTabChange, setarReload, recarregarFormPerfil }) => {
  const dispatch = useDispatch();
  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");

  const [nome, setNome] = useState("");
  const [cr, setCR] = useState("");
  const [telefone, setTelefone] = useState("");
  const [usuario, setUsuario] = useState("");
  const [email, setEmail] = useState("");
  const [usuarioAntigo, setUsuarioAntigo] = useState();
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const marginTop = isMobile ? "90px" : "90px";
  const marginBottom = isMobile ? "90px" : "90px";

  const [cpf, setCpf] = useState("");

  function tornarAlfanumerico(str) {
    return str.replace(/[^a-zA-Z0-9]/g, "");
  }

  const validarCPF = (cpf) => {
    if (typeof cpf !== "string") return false;
    cpf = cpf.replace(/[^\d]+/g, "");

    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    let soma = 0;
    for (let i = 0; i < 9; i++) soma += parseInt(cpf.charAt(i)) * (10 - i);
    let resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(9))) return false;
    soma = 0;
    for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i);
    resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(10))) return false;
    return true;
  };
  function limparStringParaNumeros(str) {
    return str.replace(/\D/g, "");
  }
  const onFinish = async (values) => {
    console.log(values);
      const data = {
        id: idUser,
        nome: values.name,
      };
/*
      try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };

        const response = await axiosInstance.post(
          "https://techfood-api.herokuapp.com/v1/usuario",
          data,
          options
        );
      } catch (error) {
        console.error("Erro ao enviar os dados:", error);

        if (error.response) {
          const mensagem = error.response.data.detail;

          notification.error({
            message: "Erro",
            description: mensagem,
          });

          setLoading(false);
          return;
        } else {
          notification.error({
            message: "Erro",
            description: "Falha ao salvar o perfil!",
          });

          setLoading(false);
          return;
        }
      }
*/
      usuarioAntigo.nome = values.name;
      usuarioAntigo.fone = limparStringParaNumeros(values.telefone);
      usuarioAntigo.cpf = values.cpf;
      usuarioAntigo.email = values.email;
      usuarioAntigo._links = null;
      try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };

        const response = await axiosInstance.put(
          `https://auth2-tech.herokuapp.com/v1/usuarios/${idUser}`,
          usuarioAntigo,
          options
        );

        notification.success({
          message: "Sucesso",
          description: "Conta salva com sucesso!",
        });
        setLoading(false);
        setTimeout(() => {
          onTabChange("subTab1");
          setarReload();
        }, 1000);
      } catch (error) {
        console.error("Erro ao enviar os dados:", error);

        // Verificar se a propriedade "response" existe no objeto de erro
        if (error.response) {
          notification.error({
            message: "Erro",
            description: error.response.data.detail,
          });

          setLoading(false);
          return;
        } else {
          // Se não houve resposta da API, exibir mensagem de erro genérica
          notification.error({
            message: "Erro",
            description: "Falha ao salvar a Conta!.",
          });

          setLoading(false);
          return;
        }
      }

  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };
        const response = await axiosInstance.get(
          "https://techfood-api.herokuapp.com/v1/usuario/" + idUser,
          options
        );

        
        setCR(response.data.cr);
        setCpf(response.data.cpf);
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setLoading(false);
      }

      try {
        setLoading(true);
        const options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        };

        const response = await axiosInstance.get(
          "https://auth2-tech.herokuapp.com/v1/usuarios/" + idUser,
          options
        );
        setNome(response.data.nome);
        setEmail(response.data.email);
        setTelefone(response.data.fone);
        setUsuario(response.data.usuario);
        setUsuarioAntigo(response.data);
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [idUser, accessToken, recarregarFormPerfil]);

  return (
    <Container>
      <Card style={{ width: "80%", marginTop: "140px", marginBottom: "110px" }}>
        <Row>
          <Col xs={24} lg={24}>
            {loading ? (
              <Skeleton active />
            ) : (
              <FormContainer>
                <Form
                  name="passwordForm"
                  onFinish={onFinish}
                  layout="vertical"
                  style={{ width: "100%" }}
                >
                  <Form.Item
                    label="Nome de Usuário"
                    name="username"
                    initialValue={usuario}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o nome de usuário!",
                      },
                    ]}
                  >
                    <Input
                      disabled
                      style={{ background: "#f0f0f0", color: "#888" }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Nome"
                    name="name"
                    initialValue={nome}
                    rules={[
                      { required: true, message: "Por favor, insira o nome!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Telefone"
                    name="telefone"
                    initialValue={telefone}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o telefone!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="E-mail"
                    name="email"
                    initialValue={email}
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o e-mail!",
                      },
                      {
                        type: "email",
                        message: "Por favor, insira um e-mail válido!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      SALVAR
                    </Button>
                  </Form.Item>
                </Form>
              </FormContainer>
            )}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Perfil;
